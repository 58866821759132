import React from 'react';
import { File } from '@model/generated/index';
import { Layout } from '@components/global/Layout';
import { graphql, PageProps } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { navigationItemsProjects } from '@model/projekte/navigationItemsProjects';
import { Meta } from '@components/global/Meta';

type DataProps = {
  image1: File;
  image2: File;
  image3: File;
  image4: File;
};

type Props = PageProps<DataProps>;

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "projekte/audiovisual_joy/image1.jpeg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
      publicURL
    }
    image2: file(relativePath: { eq: "projekte/audiovisual_joy/image2.jpeg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
      publicURL
    }
    image3: file(relativePath: { eq: "projekte/audiovisual_joy/image3.jpeg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
      publicURL
    }
    image4: file(relativePath: { eq: "projekte/audiovisual_joy/image4.jpeg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
      publicURL
    }
  }
`;

const Page: React.FC<Props> = (props) => {
  const { data } = props;
  return (
    <Layout title="Projekte" navigationItems={navigationItemsProjects}>
      <div className="flex">
        <div className="flex-auto p-32">
          <iframe
            title="1 Minute audiovisuelle Freude"
            className="h-256 lg:h-512"
            frameBorder="0"
            allow="fullscreen; display-capture; autoplay"
            src="https://player.vimeo.com/video/646441103?h=bfaca98982"
            width="100%"
          />
          <div className="flex-1 py-32 mb-16">
            <h3>1 Min Audiovisual Joy - Laurenz Theinert</h3>
            <p>
              Wie kann man künstlerischen Austausch in Zeiten der
              Kontakteinschränkung aufrecht erhalten? Wie kann man etwas Freude
              mit Kunst stiften im Lockdown?
            </p>

            <div className="flex flex-col md:flex-row">
              <GatsbyImage
                className="my-32 w-full md:w-1/2 mr-16"
                objectFit="contain"
                image={data.image2.childImageSharp.gatsbyImageData}
                alt=""
              />
              <GatsbyImage
                className="my-32 w-full md:w-1/2"
                objectFit="contain"
                image={data.image4.childImageSharp.gatsbyImageData}
                alt=""
              />
            </div>
            <p className="mb-16">
              Seit vielen Jahren ist der Künstler <b>Laurenz Theinert</b> mit
              Musiker*innen auf der ganzen Welt über die sozialen Medien
              vernetzt. Daher die Idee ein Format zu entwickeln, das diese
              Medien nutzt um zusammenzuarbeiten. Er bat alle Musiker*innen ihm
              einen einminütigen Soundtrack zu schicken. Eine Minute, weil
              Instagram eine einminütiges Limit hat. Dazu hat er jeweils
              passende abstrakte Visualisierung geschaffen. Sie hat quadratische
              Proportionen, weil Instagram alles quadratisch darstellt.
            </p>

            <div className="flex flex-col md:flex-row">
              <GatsbyImage
                className="my-32 w-full md:w-1/2 mr-16"
                objectFit="contain"
                image={data.image3.childImageSharp.gatsbyImageData}
                alt=""
              />
              <GatsbyImage
                className="my-32 w-full md:w-1/2"
                objectFit="contain"
                image={data.image1.childImageSharp.gatsbyImageData}
                alt=""
              />
            </div>
            <p>
              So sind 40 einminütige Musikvideos in einem weltweiten Team
              entstanden, die auch weltweit wahrgenommen und geteilt wurden.
              Hier werden 24 davon als audiovisueller Adventskalender gezeigt.
            </p>
            <div className="pt-32">
              <p>
                <b>Mitwirkende Musiker*innen</b>
                <br />
                Anja Kreysing, Marley Starskey Butler, laboratoire de herb mit
                Hei Fisch, Linda Kyei, Danilo Wertenauer, Volker
                Hartmann-Langenfelder, Janusz Brudniewicz, Benjamin Geyer, Tim
                Vine, Alena Leja, Betriebsgeheimnis, Babette Dieterich, Anselm
                McDonald, Hannes Theinert, Shanyio, Ines Abt, Ilkka Paloniemi,
                Sylvia Schultes, Andy Zbik, Zamal Wav, Marie Wilhelmine Anders,
                Uli Mitzlaff, Uwe Schüler, Sylvie Männel
              </p>
            </div>
            <p className="pt-32">
              <b>Öffnungszeiten:</b>
              <br />
              1. Dezember bis 24. Dezember
              <br />
              ab Einbruch der Dunkelheit
              <br />
              außerhalb der Öffnungszeiten von außen einsehbar
            </p>
            <div className="pt-32">
              <b>
                <a
                  href="https://www.laurenztheinert.de/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Zur Webseite des Künstlers
                </a>
              </b>
            </div>
          </div>
        </div>
        <h1 style={{ writingMode: 'vertical-rl' }}>1 Min Audiovisual Joy</h1>
      </div>
    </Layout>
  );
};

export const Head: React.FC<Props> = (props) => {
  return (
    <Meta
      description="Wie kann man künstlerischen Austausch in Zeiten der
      Kontakteinschränkung aufrecht erhalten? Wie kann man etwas Freude
      mit Kunst stiften im Lockdown?"
      publicURL={props.data.image3.publicURL}
      title="1 Min Audiovisual Joy"
    />
  );
};

export default Page;
